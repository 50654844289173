import React from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Avatar,
  Input,
  Typography,
  Button,
  Modal,
  Form,
} from "antd";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import airtelImage from "../assets/images/airtel.jpg";
import { useParams, useHistory } from "react-router-dom";
import mtnImage from "../assets/images/mtn.png";
import zamtelImage from "../assets/images/zamtel.png";
import liquidImage from "../assets/images/liquid.png";
import dstvImage from "../assets/images/dstv.jpg";
import gotvImage from "../assets/images/gotv.jpg";
import Loader from "../components/loader";
import { userContext } from "../context/UserContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const Vouchers = () => {
  const [form] = Form.useForm();
  const { setPerson, person, updateCgrate } = useContext(userContext);
  const { provider } = useParams();
  const [vouchers, setVouchers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const history = useHistory();

  const token = localStorage.getItem("access_token");

  const { Title, Text } = Typography;

  let image = "";

  if (provider === "Airtel") {
    image = airtelImage;
  } else if (provider === "MTN") {
    image = mtnImage;
  } else if (provider === "Zamtel") {
    image = zamtelImage;
  } else if (provider === "LiquidTelecom") {
    image = liquidImage;
  } else if (provider === "DStv") {
    image = dstvImage;
  } else if (provider === "GOtv") {
    image = gotvImage;
  }

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleViewRecord = (record) => {
    setData(record);
    setModalVisible(true);
  };

  const handleOk = () => {
    if (
      parseFloat(Math.floor(data.voucherValue).to) > parseFloat(person.balance)
    ) {
      toast.error("Insufficient Funds", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      setLoading(true);
      form
        .validateFields()
        .then((values) => {
          const postData = {
            provider: provider,
            phone: `+26${person.phone}`,
            account: values.phone,
            amount: data.voucherValue,
            vid: data.voucherId,
            vtype: data.voucherType,
            companyname: person.companyname,
            token: token,
            balance_id: person.balance_id,
            balance: person.balance,
          };

          setModalVisible(false);

          let url = "";

          if (provider === "LiquidTelecom") {
            url = `${process.env.REACT_APP_SERVER}/operation/buyliquid`;
          } else {
            url = `${process.env.REACT_APP_SERVER}/operation/purchase`;
          }

          axios
            .post(url, postData)
            .then((response) => {
              setLoading(false);

              updateCgrate();
              refreshBalance();

              Swal.fire({
                title: "Pin-Topup",
                text: `Your Purchase was Successful`,
                icon: "success",
              }).then((result) => {
                if (result.isConfirmed) {
                  history.push("/dashboard");
                }
              });
            })
            .catch((error) => {
              setLoading(false);
              toast.error("An Error Occured", {
                position: toast.POSITION.TOP_LEFT,
              });
            });
        })
        .catch((errorInfo) => {
          toast.error("An Error Occured", {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    }
  };

  const refreshBalance = () => {
    axios
      .get("https://paysmart.pickmesms.com/users/me?fields=*,balance.*", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then(async (userResponse) => {
        setPerson({
          ...person,
          balance: userResponse.data.data.balance[0].balance,
        });
      })
      .catch((userError) => {
        console.log("Error fetching user data:", userError);
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_SERVER}/operation/getvouchers`, {
        provider: provider,
      })
      .then((response) => {
        setLoading(false);
        setVouchers(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("axios error: " + error);
      });
  }, [provider]);

  const columns = [
    {
      title: "VOUCHER",
      dataIndex: "voucherType",
      key: "voucherId",
      width: "32%",
      render: (voucherType, record) => (
        // Customize the rendering of the 'name' cell
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="square"
            size={40}
            src={image}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}>{voucherType}</Title>
            {/* <p>Serial Number : {record.serial_number}</p> */}
          </div>
        </Avatar.Group>
      ),
    },
    {
      title: "VALUE",
      dataIndex: "voucherValue",
      key: "voucherValue",
      render: (voucherValue) => (
        <>
          <div className="author-info">
            <Title level={5}>{"K" + Math.floor(voucherValue)}</Title>
          </div>
        </>
      ),
    },

    {
      title: "BUY",
      key: "voucherId",
      dataIndex: "voucherId",
      render: (voucherId, record) => (
        <>
          <Button
            type="secondary"
            className="tag-secondary"
            onClick={() => handleViewRecord(record)}
          >
            BUY
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="tabled">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Vouchers"
                extra={
                  <>
                    <Input.Search
                      placeholder="Search"
                      onChange={(e) => handleSearch(e.target.value)}
                      value={searchText}
                    />
                  </>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={vouchers}
                    className="ant-border-space"
                    pagination={{
                      pageSize: 5,
                    }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
      <Modal
        title="Account Details"
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {data.serviceProvider === "LiquidTelecom" ? (
          <Form form={form} layout="vertical">
            <Form.Item
              label="LiquidTelecom Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone number starting with 260",
                },
              ]}
            >
              <Input placeholder="Phone number starting with 260" />
            </Form.Item>
          </Form>
        ) : (
          <Form form={form} layout="vertical">
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone number starting with 0",
                },
              ]}
            >
              <Input placeholder="Phone number starting with 0" />
            </Form.Item>
            <Text
              type="secondary"
              style={{ marginBottom: "16px", display: "block" }}
            ></Text>
            <p>Amount : {Math.floor(data.voucherValue).toFixed(2)}</p>
            <p>Client Balance : {parseFloat(person.balance).toFixed(2)}</p>
            <p>
              {parseFloat(Math.floor(data.voucherValue).toFixed(2)) >
              parseFloat(person.balance).toFixed(2)
                ? "Insuficient funds"
                : "Funds available"}
            </p>
          </Form>
        )}
      </Modal>

      <ToastContainer />
    </>
  );
};

export default Vouchers;
