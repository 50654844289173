import {
  Row,
  Col,
  Card,
  Descriptions,
  Typography,
  Form,
  Input,
  Button,
  Switch,
  message,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Loader from "../components/loader";
import { userContext } from "../context/UserContext";
import { useHistory } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";

const Loandetails = () => {
  const location = useLocation();
  const loan = location.state.loan;
  const [myloan, setMyloan] = useState();
  const [loading, setLoading] = useState(false);
  const { branch } = useContext(userContext);
  const history = useHistory();

  const { Title } = Typography;

  const picContainerStyle = {
    backgroundImage:
      loan &&
      loan.collateral &&
      loan.collateral[0] &&
      loan.collateral[0].collateral_picture
        ? `url(${loan.collateral[0].collateral_picture})`
        : "",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    width: "80%",
    height: "800px",
  };

  const rate = loan.loantype.interest_rate / 100;

  const repayable = loan.amount + rate * loan.amount;

  const onFinish = async (values) => {
    try {
      // Make a POST request using Axios
      await axios.post("https://cgrate.pickmesms.com/makepayment", values);

      // You can perform further actions with the API response here
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  const onAddPayment = async (values) => {
    const newbalance = myloan.balance - values.amount;

    const updatedLoan = { ...myloan, balance: newbalance };

    setLoading(true);

    axios
      .patch(
        `https://gpnserver.pickmesms.com/items/loans/${myloan.id}`,
        updatedLoan,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const message = `We have received your part payment of k${values.amount} Your balance is k${newbalance}`;

        sendpaymentSMS(message);
        addTransaction(myloan.id, values.amount, branch.id, "Loan Repayment");
      })
      .catch((error) => {
        // Handle the error
      });
  };

  const addTransaction = (loanid, amount, branchid, type) => {
    const transaction = {
      type: type,
      amount: amount,
      loan: loanid,
      branch: branchid,
    };

    axios
      .post(
        `https://gpnserver.pickmesms.com/items/gpntransactions`,
        transaction,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log(response);
        history.push("/loans");
      })
      .catch((error) => {
        // Handle the error
        //  console.log(error);
      });
  };

  const handleSubmit = () => {
    setLoading(true);

    axios
      .patch(
        `https://gpnserver.pickmesms.com/items/loans/${myloan.id}`,
        myloan,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        sendSMS();
        addTransaction(
          myloan.id,
          myloan.amount,
          branch.id,
          "Loan Disbursement"
        );
      })
      .catch((error) => {
        // Handle the error
      });
  };

  const handleDelete = () => {
    setLoading(true);

    axios
      .delete(`https://gpnserver.pickmesms.com/items/loans/${myloan.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const message =
          "Your loan application was rejected. Please Try again next time";
        sendpaymentSMS(message);
        history.push("/sign-in");
      })
      .catch((error) => {
        // Handle the error
      });
  };

  const sendpaymentSMS = async (msg) => {
    setLoading(false);

    const values = {
      phone: myloan.debtor_id.phone,
      message: msg,
    };

    try {
      // Make a POST request using Axios
      await axios.post("https://cgrate.pickmesms.com/gpnsms", values);

      // You can perform further actions with the API response here
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  const sendSMS = async () => {
    setLoading(false);
    let message = "";
    if (myloan.status == "approved") {
      message = `Congratulations! your GPN Loan of K${myloan.amount} has been aproved.`;
    } else {
      message = "Your GPN loan has been reversed";
    }

    const values = {
      phone: myloan.debtor_id.phone,
      message: message,
    };

    try {
      // Make a POST request using Axios
      await axios.post("https://cgrate.pickmesms.com/gpnsms", values);

      // You can perform further actions with the API response here
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  const onChange = (value) => {
    let newstatus = "pending";
    if (value) {
      newstatus = "approved";
    } else {
      newstatus = "pending";
    }
    setMyloan({ ...myloan, status: newstatus });
  };

  useEffect(() => {
    setMyloan(loan);
  }, [loan]);

  const addDays = (numberOfDaysToAdd, date) => {
    const [day, month, year] = date.split("-").map(Number);

    // Create a new Date object
    const originalDate = new Date(year, month - 1, day); // Months are 0-based

    originalDate.setDate(originalDate.getDate() + numberOfDaysToAdd);

    // Convert the new date to the desired format (dd-mm-yyyy)
    const newDay = String(originalDate.getDate()).padStart(2, "0");
    const newMonth = String(originalDate.getMonth() + 1).padStart(2, "0");
    const newYear = originalDate.getFullYear();
    const formattedNewDate = `${newDay}-${newMonth}-${newYear}`;

    return formattedNewDate;
  };

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col span={24} md={8} className="mb-24">
            <Card
              bordered={false}
              title={
                <h6 className="font-semibold m-0">
                  Loan Summary :{" "}
                  {loan.debtor_id.first_name + " " + loan.debtor_id.last_name}
                </h6>
              }
              className="header-solid h-full card-profile-information"
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <hr className="my-10" />
              <Descriptions>
                <Descriptions.Item label="Loan Amount" span={3}>
                  K{parseFloat(loan.amount).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label="Applied On" span={3}>
                  {loan.loan_date}
                </Descriptions.Item>
                <Descriptions.Item label="Due Date" span={3}>
                  {addDays(loan.loantype.duration, loan.loan_date)}
                </Descriptions.Item>
                <Descriptions.Item label="Loan Type" span={6}>
                  {loan.loantype.loan_name}
                </Descriptions.Item>
                <Descriptions.Item label="Interest Rate" span={6}>
                  {loan.loantype.interest_rate}%
                </Descriptions.Item>
                <Descriptions.Item label="Balance" span={3}>
                  K{parseFloat(loan.balance).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label="Guarantor Name" span={3}>
                  {loan.guarantor_name}
                </Descriptions.Item>
                <Descriptions.Item label="Guarantor Phone" span={3}>
                  {loan.guarantor_phone}
                </Descriptions.Item>
                <Descriptions.Item label="Status" span={3}>
                  <span
                    style={{
                      color:
                        loan.status === "pending"
                          ? "orange"
                          : loan.status === "approved"
                          ? "green"
                          : "black",
                    }}
                  >
                    {loan.status}
                  </span>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>

          <Col span={24} md={8} className="mb-24">
            <Card>
              <Title level={5}>Add Payment</Title>
              <Form name="myForm" onFinish={onAddPayment}>
                <Form.Item
                  className="username"
                  label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the amount!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Amount" />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>

          {/* <Col span={24} md={8} className="mb-24">
            <Card>
              <Title level={5}>Add Payment (Mobile Money)</Title>
              <Form name="myForm" onFinish={onFinish}>
                <Form.Item
                  className="username"
                  label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the amount!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Amount" />
                </Form.Item>
                <Form.Item
                  className="username"
                  label="Phone"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone number!",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Phone" />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col> */}

          <Col span={24} md={8} className="mb-24">
            <Card bordered={true}>
              <Row justify="space-between">
                <Col>
                  <Title level={5}>Approval Status</Title>
                </Col>
                <Col>
                  <Switch
                    checked={myloan && myloan.status === "approved"}
                    onChange={(value) => onChange(value)}
                  />
                </Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </Row>
            </Card>

            {/* Add space between the two cards */}
            <div style={{ margin: "20px 0" }}></div>

            <Card bordered={true}>
              <Row justify="space-between">
                <Col>
                  <Title level={5}>Delete Application</Title>
                </Col>

                <Button
                  type="danger"
                  htmlType="submit"
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Delete
                </Button>
              </Row>
            </Card>
            <Loader loading={loading} />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col span={24} md={24} className="mb-24">
            <Card
              className="header-solid h-full"
              bordered={false}
              title={
                <h6 className="font-semibold m-0">
                  Collateral Placed :{" "}
                  {loan && loan.collateral && loan.collateral[0]
                    ? loan.collateral[0].collateral_name
                    : "N/A"}
                </h6>
              }
              bodyStyle={{ paddingTop: "0" }}
            >
              <Row gutter={[24, 24]}>
                <div style={picContainerStyle}></div>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Loandetails;
