import { Row, Col, Card, Table, Avatar, Input, Typography } from "antd";

import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";

const Collateral = () => {
  const [collateral, setCollateral] = useState([]);

  const [searchText, setSearchText] = useState("");

  const handleSearch = (value) => {
    setSearchText(value);
  };
  const { Title } = Typography;
  const getCollateral = async () => {
    try {
      //setLoading(true);
      const response = await axios.get(
        `https://gpnserver.pickmesms.com/items/collateral?fields=*.*`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      //setLoading(false);

      setCollateral(response.data.data);
    } catch (error) {
      //setLoading(false);
      //message.error(error.message);
    }
  };

  useEffect(() => {
    getCollateral();
  }, []);

  const columns = [
    {
      title: "COLLATERAL",
      dataIndex: "collateral_name",
      key: "collateral_name",
      width: "32%",
      render: (collateral_name, record) => (
        // Customize the rendering of the 'name' cell
        <Avatar.Group>
          <Avatar
            className="shape-avatar"
            shape="square"
            size={40}
            src={record.collateral_picture}
          ></Avatar>
          <div className="avatar-info">
            <Title level={5}>{collateral_name}</Title>
            <p>Serial Number : {record.serial_number}</p>
          </div>
        </Avatar.Group>
      ),
    },
    {
      title: "COLLATERAL ADDRESS",
      dataIndex: "collateral_address",
      key: "collateral_address",
      render: (collateral_address) => (
        <>
          <div className="author-info">
            <Title level={5}>{collateral_address}</Title>
          </div>
        </>
      ),
    },

    {
      title: "VALUE",
      dataIndex: "value",
      key: "value",
      render: (value) => (
        <>
          <div className="author-info">
            <Title level={5}>K{value}</Title>
          </div>
        </>
      ),
    },

    {
      title: "DEBTOR",
      key: "debtor",
      dataIndex: "debtor",
      render: (debtor, record) => (
        <>
          <div className="author-info">
            <Title level={5}>
              {record.debtor.first_name + " " + record.debtor.last_name}
            </Title>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Collateral"
              extra={
                <>
                  <Input.Search
                    placeholder="Search"
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchText}
                  />
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={collateral}
                  className="ant-border-space"
                  pagination={{
                    pageSize: 5,
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Collateral;
