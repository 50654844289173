// AuthenticatedRoute.js
import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { userContext } from "../context/UserContext";
import { isTokenExpired } from "./utils";

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(userContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        user.access_token && !isTokenExpired() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
