import {
  Row,
  Col,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
} from "antd";
import axios from "axios";
import React, { useState, useContext } from "react";
import Loader from "../components/loader";
import { userContext } from "../context/UserContext";
import { useLocation, useHistory } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const Adduser = () => {
  const history = useHistory();

  const { Title } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { person, updateCgrate } = useContext(userContext);
  const token = localStorage.getItem("access_token");

  const options = [
    {
      label: "Airtime",
      value: "airtime",
    },
    {
      label: "Deposits",
      value: "momo",
    },
  ];

  const validatePhoneNumber = (_, value) => {
    if (!value || /^\d{10}$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject("Please enter a valid 10-digit phone number.");
  };

  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  const addBalance = async (user_id, balance) => {
    try {
      setLoading(true);
      // Make a POST request using Axios
      await axios
        .post(
          "https://paysmart.pickmesms.com/items/balances",
          JSON.stringify({ balance: balance, client: user_id }),
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const user_id = response.data.data.id;

          form.resetFields();
          setLoading(false);
          history.push("/clients");
        });
    } catch (error) {
      setLoading(false);

      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  const onFinish = async (values) => {
    const postData = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      status: "active",
      companyname: values.companyname,
      role: "aba355e7-ff5b-44f8-97ec-81e06f7b8a95",
      phone: values.phone,
      service_rights: values.service_rights,
    };

    try {
      setLoading(true);
      // Make a POST request using Axios
      await axios
        .post(
          "https://paysmart.pickmesms.com/users",
          JSON.stringify(postData),
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const user_id = response.data.data.id;

          addBalance(user_id, values.balance);
        });
    } catch (error) {
      setLoading(false);

      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  return (
    <div className="layout-content">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Add Paysmart Client</Title>
            <Form name="myForm" onFinish={onFinish} form={form}>
              <Form.Item
                className="username"
                label="Company Name"
                name="companyname"
                rules={[
                  {
                    required: true,
                    message: "Please enter Company Name",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter Company Name",
                    },
                  ]}
                  placeholder="Company Name"
                />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Last Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Email",
                      },
                    ]}
                  >
                    <Input type="email" placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Password",
                      },
                    ]}
                  >
                    <Input.Password
                      placeholder="User password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Phone number"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number",
                      },
                    ]}
                  >
                    <Input
                      rules={[
                        {
                          required: true,
                          message: "Please enter phone number",
                        },
                        {
                          validator: validatePhoneNumber,
                        },
                      ]}
                      type="number"
                      placeholder="Phone starting with 0"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="username"
                    label="Account Balance"
                    name="balance"
                    rules={[
                      {
                        required: true,
                        message: "Please enter balance",
                      },
                    ]}
                  >
                    <Input
                      rules={[
                        {
                          required: true,
                          message: "Please enter balance",
                        },
                      ]}
                      type="number"
                      placeholder="Account Balance"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                className="username"
                label="Enable Services"
                name="service_rights"
                rules={[
                  {
                    required: true,
                    message: "Please enter balance",
                  },
                ]}
              >
                <Checkbox.Group options={options} defaultValue={["airtime"]} />
              </Form.Item>

              <Loader loading={loading} />
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Adduser;
