import { Row, Col, Card, Typography, Form, Input, Button } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Loader from "../components/loader";
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const Sms = () => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [bulkloading, setBulkLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const [clients, setClients] = useState([]);
  const token = localStorage.getItem("access_token");

  const getClients = async () => {
    try {
      const response = await axios.get(
        `https://paysmart.pickmesms.com/users?fields=phone`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      setClients(response.data.data);
    } catch (error) {
      if (error.response.status === 401) {
        history.push("/sign-in");
      }
    }
  };

  const onFinish = async (values) => {
    const vphone = values.phone;
    const phone = `+26${vphone}`;
    const message = values.message;

    try {
      setLoading(true);
      // Make a POST request using Axios
      await axios
        .post(`${process.env.REACT_APP_SERVER}/operation/singlesms`, {
          phone: phone,
          message: message,
          token: token,
        })
        .then((response) => {
          form.resetFields();
          setLoading(false);
          console.log(response);

          Swal.fire({
            title: "Message Sent",
            text: `${response.data.message}`,
            icon: "success",
          });
        });
    } catch (error) {
      setLoading(false);

      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  const onBulkFinish = async (values) => {
    const numbers = clients.map((obj) => `+26${obj.phone}`);

    const postData = {
      message: values.message,
      phone: numbers,
      token: token,
    };

    try {
      setBulkLoading(true);
      // Make a POST request using Axios
      await axios
        .post(`${process.env.REACT_APP_SERVER}/operation/singlesms`, postData)
        .then((response) => {
          form.resetFields();
          setBulkLoading(false);

          Swal.fire({
            title: "Message Sent",
            text: `${response.data.message}`,
            icon: "success",
          });
        });
    } catch (error) {
      setBulkLoading(false);

      console.error("Error submitting form:", error);
      // Handle error cases
    }
  };

  useEffect(() => {
    getClients();
  });

  return (
    <div className="layout-content">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Single SMS</Title>
            <Form name="myForm" onFinish={onFinish} form={form}>
              <Form.Item
                className="username"
                label="Phone Number"
                name="phone"
                initialValue={
                  location.state && location.state.phone
                    ? location.state.phone
                    : ""
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number",
                    },
                  ]}
                  type="number"
                  placeholder="Phone number starting with 0"
                />
              </Form.Item>
              <Form.Item
                className="username"
                label="Message"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter Message",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Message" />
              </Form.Item>
              <Loader loading={loading} />
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Bulk SMS</Title>
            <Form name="myForm" onFinish={onBulkFinish}>
              <Form.Item
                className="username"
                label="Send Message to all Paysmart Users"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter Message",
                  },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Message" />
              </Form.Item>
              <Loader loading={bulkloading} />

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Sms;
