/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { useContext } from "react";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/paysmart.png";
import { userContext } from "../../context/UserContext";
import {
  FileFilled,
  UsergroupAddOutlined,
  LogoutOutlined,
  FileExcelFilled,
  MoneyCollectFilled,
  SendOutlined,
  DashOutlined,
  TransactionOutlined,
  PhoneOutlined,
  PieChartOutlined,
  FolderAddFilled,
} from "@ant-design/icons";
import VerticalImageSidebar from "./verticalImageSidebar";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { person } = useContext(userContext);
  const role = localStorage.getItem("role");

  const goToProfile = () => {
    console.log(person);
  };

  const billing = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  return (
    <>
      <div
        className="brand"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#4169e1",
          marginTop: "30px",
        }}
      >
        {/* <img
          src={logo}
          alt=""
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        /> */}
      </div>
      <hr />
      {role === "606985a4-a39e-4ffc-b0ba-095e82d13316" ? (
        <Menu theme="light" mode="inline"></Menu>
      ) : (
        <Menu theme="light" mode="inline">
          <Menu.Item key="1">
            <NavLink to="/dashboard">
              <span
                className="icon"
                style={{
                  background: page === "dashboard" ? color : "",
                }}
              >
                <DashOutlined />
              </span>
              <span className="label">Dashboard</span>
            </NavLink>
          </Menu.Item>
          {role == "aba355e7-ff5b-44f8-97ec-81e06f7b8a95" ? (
            ""
          ) : (
            <Menu.Item key="2">
              <NavLink to="/clients">
                <span
                  className="icon"
                  style={{
                    background: page === "clients" ? "#4169E1" : "",
                  }}
                >
                  <UsergroupAddOutlined />
                </span>
                <span className="label">Clients</span>
              </NavLink>
            </Menu.Item>
          )}

          {person.service_rights.includes("airtime") ? (
            <Menu.Item key="3">
              <NavLink to="/talktime">
                <span
                  className="icon"
                  style={{
                    background: page === "talktime" ? color : "",
                  }}
                >
                  <PhoneOutlined />
                </span>
                <span className="label">Buy Talk Time</span>
              </NavLink>
            </Menu.Item>
          ) : (
            <></>
          )}

          {person.service_rights.includes("momo") ? (
            <Menu.Item key="33">
              <NavLink to="/deposits">
                <span
                  className="icon"
                  style={{
                    background: page === "deposits" ? color : "",
                  }}
                >
                  {billing}
                </span>
                <span className="label">Send Money</span>
              </NavLink>
            </Menu.Item>
          ) : (
            <></>
          )}

          <Menu.Item key="4">
            <NavLink to="/transactions">
              <span
                className="icon"
                style={{
                  background: page === "transactions" ? color : "",
                }}
              >
                <TransactionOutlined />
              </span>
              <span className="label">Transaction History</span>
            </NavLink>
          </Menu.Item>

          {role == "aba355e7-ff5b-44f8-97ec-81e06f7b8a95" ? (
            ""
          ) : (
            <Menu.Item key="6221">
              <NavLink to="/recons">
                <span
                  className="icon"
                  style={{
                    background: page === "recons" ? color : "",
                  }}
                >
                  <PieChartOutlined />
                </span>
                <span className="label">Recons</span>
              </NavLink>
            </Menu.Item>
          )}

          {role == "aba355e7-ff5b-44f8-97ec-81e06f7b8a95" ? (
            ""
          ) : (
            <Menu.Item key="6">
              <NavLink to="/sms">
                <span
                  className="icon"
                  style={{
                    background: page === "sms" ? color : "",
                  }}
                >
                  <SendOutlined />
                </span>
                <span className="label">Bulk SMS</span>
              </NavLink>
            </Menu.Item>
          )}

          <Menu.Item key="63">
            <NavLink to="/topup">
              <span
                className="icon"
                style={{
                  background: page === "topup" ? color : "",
                }}
              >
                <MoneyCollectFilled />
              </span>
              <span className="label">Topup</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="104">
            <NavLink to="/login">
              <span
                className="icon"
                style={{
                  background: page === "sign-in" ? color : "",
                }}
              >
                <LogoutOutlined />
              </span>
              <span className="label">Logout</span>
            </NavLink>
          </Menu.Item>
          {pathname == "/dashboard" ? <VerticalImageSidebar /> : <></>}
        </Menu>
      )}
    </>
  );
}

export default Sidenav;
