import {
  Row,
  Col,
  Card,
  Descriptions,
  Button,
  Table,
  Empty,
  Typography,
  Checkbox,
  Input,
} from "antd";
import { UploadOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import React from "react";
import Loader from "../components/loader";
import { useHistory } from "react-router-dom";
import { userContext } from "../context/UserContext";
import Swal from "sweetalert2";

const Overview = ({ client, showToast }) => {
  const { Title } = Typography;

  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [services, setServices] = useState();
  const { person, setPerson } = useContext(userContext);
  const [isMobileEditing, setMobileEditing] = useState(false);
  const [isEmailediting, setEmailEditing] = useState(false);

  const history = useHistory();

  const getAlerts = async () => {
    setLoading(true);

    const phne = "+26" + client.phone;

    const encodedPhoneNumber = encodeURIComponent(phne);

    try {
      const response = await axios.get(
        `https://paysmart.pickmesms.com/items/messages?filter[phone][_eq]=${encodedPhoneNumber}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);

      setAlerts(response.data.data);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        history.push("/sign-in");
      }
    }
  };

  const handleMobileClick = () => {
    setMobileEditing(true);
  };

  const handleEmailClick = () => {
    setEmailEditing(true);
  };
  const handleBalanceChange = (e) => {
    setBalance(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onServiceChange = (checkedValues) => {
    console.log("checked = ", checkedValues.target.checked);
  };

  const updateUser = async () => {
    setLoading(true);
    axios
      .patch(
        `https://paysmart.pickmesms.com/users/${client.id}`,
        { phone: phone, email: email },

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (person.id == client.id) {
          setPerson({ ...person, email: email, phone: phone });
        }
        updateBalance();
      })
      .catch((error) => {
        // Handle the error
      });
  };

  const updateBalance = async () => {
    const newbalance = parseInt(balance) + parseInt(client.balance[0].balance);
    axios
      .patch(
        `https://paysmart.pickmesms.com/items/balances/${client.balance[0].id}`,
        { balance: newbalance },

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setLoading(false);

        if (person.id == client.id) {
          setPerson({ ...person, balance: newbalance });
        }

        Swal.fire({
          title: `User Profile`,
          text: `Update was successful`,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            if (balance > 0) {
              sendSMS();
              updateTransaction(balance, newbalance);
            }
          }
          history.push("/clients");
        });
      })
      .catch((error) => {
        // Handle the error
      });
  };

  const updateTransaction = async (amount, balance) => {
    const postData = {
      amount: amount,
      balance: balance,
      companyname: client.companyname,
      token: localStorage.getItem("access_token"),
    };

    await axios
      .post(
        `${process.env.REACT_APP_SERVER}/operation/admin_topup_transaction`,
        postData
      )
      .then((response) => {
        console.log(response);
      });
  };

  const sendSMS = async () => {
    const token = localStorage.getItem("access_token");

    const postData = {
      message: `Your account has been topped up with K${balance}`,
      phone: `+26${client.phone}`,
      token: token,
    };

    try {
      await axios
        .post(`${process.env.REACT_APP_SERVER}/operation/singlesms`, postData)
        .then((response) => {});
    } catch (error) {}
  };

  const handleMobileBlur = () => {
    setMobileEditing(false);
  };

  const handleEmailBlur = () => {
    setEmailEditing(false);
  };

  const updatePassword = async () => {};

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    getAlerts();
    setServices(client.service_rights);
    setPhone(client.phone);
    setEmail(client.email);
  }, [client.id, client.service_rights]);

  const humanize = (mydate) => {
    const date = new Date(mydate);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const formattedDate = date.toLocaleString("en-US", options);

    return formattedDate;
  };

  const columns = [
    {
      title: "DATE",
      dataIndex: "date_created",
      key: "date_created",
      render: (date) => (
        <>
          <div className="author-info">
            <p level={5}>{humanize(date)}</p>
          </div>
        </>
      ),
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      key: "message",
      render: (message) => (
        <>
          <div
            className="author-info"
            style={{ whiteSpace: "normal", maxWidth: "300px" }}
          >
            <p>{message}</p>
          </div>
        </>
      ),
    },
  ];

  return (
    <Row gutter={[24, 0]}>
      <Col span={24} md={8} className="mb-24">
        <Card
          bordered={false}
          title={<h6 className="font-semibold m-0">Profile Information</h6>}
          className="header-solid h-full card-profile-information"
          bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
        >
          <hr className="my-10" />
          <Loader loading={loading} />
          <Descriptions>
            <Descriptions.Item label="Business" span={3}>
              {client.companyname}
            </Descriptions.Item>
            <Descriptions.Item label="User" span={3}>
              {client.first_name + " " + client.last_name}
            </Descriptions.Item>
            <Descriptions.Item label="Mobile" span={3}>
              {isMobileEditing ? (
                <Input
                  onChange={handlePhoneChange}
                  value={phone}
                  onBlur={handleMobileBlur}
                  autoFocus
                />
              ) : (
                <span onClick={handleMobileClick}>
                  {phone}
                  <EditOutlined
                    onClick={handleMobileClick}
                    style={{ color: "blue", marginLeft: 5, cursor: "pointer" }}
                  />
                </span>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Email" span={3}>
              {isEmailediting ? (
                <Input
                  onChange={handleEmailChange}
                  value={email}
                  onBlur={handleEmailBlur}
                  autoFocus
                />
              ) : (
                <span onClick={handleEmailClick}>
                  {email}
                  <EditOutlined
                    onClick={handleMobileClick}
                    style={{ color: "blue", marginLeft: 5, cursor: "pointer" }}
                  />
                </span>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Services" span={3}>
              {client.service_rights.join(", ")}
            </Descriptions.Item>

            <Descriptions.Item label="Add Funds" span={2}>
              <Input
                className="balance"
                onChange={handleBalanceChange}
                placeholder="Add Funds"
                defaultValue={0}
              />
            </Descriptions.Item>
          </Descriptions>
          <Button type="primary" onClick={updateUser}>
            Submit Changes
          </Button>
        </Card>
      </Col>
      <Col span={24} md={16} className="mb-24 ">
        <div className="tabled">
          {loading ? (
            <Loader />
          ) : (
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title="Inbox"
                >
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={alerts}
                      className="ant-border-space"
                      pagination={{
                        pageSize: 5,
                      }}
                      locale={{
                        emptyText: <Empty description="No Messages" />,
                      }}
                    />
                  </div>
                </Card>
              </Col>
              <Loader loading={loading} />
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Overview;
