import { useContext, useState, useEffect, PureComponent } from "react";
import axios from "axios";
import { DownloadOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Row,
  Avatar,
  Button,
  Typography,
  Table,
  Input,
  Empty,
  Collapse,
  Badge,
  Space,
  DatePicker,
} from "antd";
import { CSVLink } from "react-csv";
import {
  UnorderedListOutlined,
  BankOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Loader from "../components/loader";
import { useHistory } from "react-router-dom";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const Recons = () => {
  const { Title } = Typography;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(
    dayjs().add(1, "day").format("YYYY-MM-DD")
  );
  const [totalTopups, setTotalTopups] = useState(0);
  const [totalNonTopups, setTotalNonTopups] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);

  const history = useHistory();

  const dollor = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z"
        fill="#fff"
      ></path>
      <path
        d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z"
        fill="#fff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z"
        fill="#fff"
      ></path>
    </svg>,
  ];

  const count = [
    {
      title: "Total Topups",
      // value: "K" + parseFloat(person.balance).toFixed(2),
      value:
        "K" +
        parseFloat(totalTopups)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      icon: dollor,
      bnb: "bnb2",
    },
    {
      title: "Total Transactions",
      value:
        "K" +
        parseFloat(totalNonTopups)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      icon: dollor,
      bnb: "bnb2",
    },
    {
      title: "Total Usage",
      value:
        "K" +
        parseFloat(totalTopups - totalNonTopups)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      icon: dollor,
      bnb: "bnb2",
    },
    {
      title: "Total Virtual Balance",
      value:
        "K" +
        parseFloat(totalBalance)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      icon: dollor,
      bnb: "bnb2",
    },
  ];

  const handleDateChange = (dates) => {
    let [start, end] = dates;
    //console.log(start.format("YYYY-MM-DD"));
    setStartDate(start.format("YYYY-MM-DD"));
    setEndDate(end.format("YYYY-MM-DD"));
    getItems();
  };

  const getItems = async () => {
    const url = `https://paysmart.pickmesms.com/items/transactions?filter[date_created][_between]=[${startDate},${endDate}]`;

    try {
      setLoading(true);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      });
      setLoading(false);

      let accountTopupSum = 0;
      let accountNonTopupSum = 0;

      const groupedItems = response.data.data.reduce((result, currentItem) => {
        if (currentItem.transtype === "Account Topup") {
          accountTopupSum += parseInt(currentItem.amount, 10);
          // If it's a top-up, accumulate the sum for each account
          if (currentItem.account in result) {
            result[currentItem.account].topups += parseInt(
              currentItem.amount,
              10
            );
          } else {
            result[currentItem.account] = {
              account: currentItem.account,
              topups: parseInt(currentItem.amount, 10),
              transactions: 0,
              balance: currentItem.balance,
            };
          }
        } else {
          accountNonTopupSum += parseInt(currentItem.amount, 10);
          // If it's a non-top-up, accumulate the sum for each account
          if (currentItem.account in result) {
            result[currentItem.account].transactions += parseInt(
              currentItem.amount,
              10
            );
          } else {
            result[currentItem.account] = {
              account: currentItem.account,
              topups: 0,
              transactions: parseInt(currentItem.amount, 10),
              balance: currentItem.balance,
            };
          }
        }
        return result;
      }, {});

      const finalItems = Object.values(groupedItems);

      setItems(finalItems);
      setTotalTopups(accountTopupSum);
      setTotalNonTopups(accountNonTopupSum);

      let totalBalance = 0;
      finalItems.forEach((record) => {
        totalBalance += parseFloat(record.balance);
      });
      setTotalBalance(totalBalance);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        history.push("/sign-in");
      }
    }
  };

  useEffect(() => {
    getItems();
  }, [startDate, endDate]);

  const columns = [
    {
      title: "Account",
      dataIndex: "account",
      key: "account",
      render: (account, record) => (
        <>
          <div className="author-info">
            <h6 level={5}>{account}</h6>
          </div>
        </>
      ),
    },
    {
      title: "Total Topups",
      dataIndex: "topups",
      key: "topups",
      render: (topups, record) => (
        <>
          <div className="author-info">
            <h6 level={5}>
              {"K" +
                parseFloat(topups)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </h6>
          </div>
        </>
      ),
    },
    {
      title: "Total Transactions",
      dataIndex: "transactions",
      key: "transactions",
      render: (transactions, record) => (
        <>
          <div className="author-info">
            <h6 level={5}>
              {"K" +
                parseFloat(transactions)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </h6>
          </div>
        </>
      ),
    },
    {
      title: "Usage",
      dataIndex: "transactions",
      key: "transactions",
      render: (transactions, record) => (
        <>
          <div className="author-info">
            <h6 level={5}>
              {" "}
              {"K" +
                (
                  parseFloat(record.topups).toFixed(2) -
                  parseFloat(record.transactions).toFixed(2)
                ).toLocaleString()}
            </h6>
          </div>
        </>
      ),
    },
    {
      title: "Current Virtual Balance",
      dataIndex: "balance",
      key: "balance",
      render: (balance, record) => (
        <>
          <div className="author-info">
            <h6 level={5}>
              {"K" + parseFloat(balance).toFixed(2).toLocaleString()}
            </h6>
          </div>
        </>
      ),
    },

    // Add other columns as needed
  ];

  return (
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Reconciliation"
              extra={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <RangePicker
                    placeholder={["Start Date", "End Date"]}
                    defaultValue={[
                      dayjs(startDate, dateFormat),
                      dayjs(endDate, dateFormat),
                    ]}
                    format={dateFormat}
                    onChange={handleDateChange}
                  />

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "8px",
                    }}
                  ></div>
                </div>
              }
            ></Card>
          </Col>
        </Row>
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={6}
              className="mb-24"
            >
              <Card bordered={true} className="branchbox ">
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={18}>
                      <span>{c.title}</span>
                      <Title level={4}>
                        {c.value} <small className={c.bnb}></small>
                      </Title>
                    </Col>
                    <Col xs={6}>
                      <div className="icon-box">{c.icon}</div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <div className="tabled">
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title="Accounts"
                  extra={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CSVLink
                        data={items}
                        filename={`recon_${startDate}_${endDate}.csv`}
                        className="ant-btn"
                      >
                        <DownloadOutlined
                          style={{
                            fontSize: "24px",
                            marginTop: "4px",
                            marginLeft: "4px",
                          }}
                        />
                      </CSVLink>
                    </div>
                  }
                >
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={items}
                      className="ant-border-space"
                      pagination={{
                        pageSize: 10,
                      }}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default Recons;
