// CardComponent.jsx
import React from "react";
import { Card, Row, Col, Typography } from "antd";

const { Title } = Typography;

const CustomCard = ({
  img,
  image,
  title,
  description,
  provider,
  onCardClick,
}) => {
  const handleClick = () => {
    // Call the method in the main component and pass the provider as an argument
    onCardClick(provider, img);
  };

  return (
    <a
      href="#"
      onClick={handleClick}
      style={{ textDecoration: "none", cursor: "pointer", display: "block" }}
    >
      {title == "" ? (
        <></>
      ) : (
        <Card
          bordered={true}
          className="branchbox"
          hoverable
          style={{ height: "100%" }}
        >
          <div className="number">
            <Row
              align="middle"
              justify="center"
              gutter={[24, 0]}
              style={{ height: "100%" }}
            >
              <Col xs={24}>
                <div style={{ textAlign: "center", height: "70%" }}>
                  <img
                    src={image}
                    alt={title}
                    style={{ width: "50%", height: "auto" }}
                  />
                </div>
                <Title
                  level={5}
                  style={{
                    textAlign: "center",
                    height: "30%",
                  }}
                >
                  {title} <br />
                  <small className="someClass">{description}</small>
                </Title>
              </Col>
            </Row>
          </div>
        </Card>
      )}
    </a>
  );
};

export default CustomCard;
