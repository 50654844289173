import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

export const userContext = createContext();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    access_token: "",
    expires: "",
    refresh_token: "",
  });

  const [person, setPerson] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    tfa_secret: null,
    balance: "",
    balance_id: "",
    companyname: "",
    role: "",
    avatar: "",
    service_rights: [],
  });

  const [cgrate, setCgrate] = useState({
    balance: "",
  });

  const [branch, setBranch] = useState({});

  const history = useHistory();

  const updateCgrate = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/operation/mainbalance`)
      .then((response) => {
        setCgrate({
          balance: response.data.balance,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateUser = async (values) => {
    axios
      .post("https://paysmart.pickmesms.com/auth/login", {
        email: values.email,
        password: values.password,
      })
      .then((response) => {
        const { access_token, expires, refresh_token } = response.data.data;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("expires", expires);

        // Set the user state
        setUser({
          access_token,
          expires,
          refresh_token,
        });

        // Fetch the actual user data using the bearer token (access_token)
        axios
          .get("https://paysmart.pickmesms.com/users/me?fields=*,balance.*", {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          .then(async (userResponse) => {
            const otp = Math.floor(Math.random() * 900000) + 100000;

            const {
              id,
              status,
              role,
              first_name,
              last_name,
              email,
              phone,
              tfa_secret,
              companyname,
              service_rights,
              avatar,
            } = userResponse.data.data;

            setPerson({
              id,
              status,
              first_name,
              last_name,
              email,
              phone,
              tfa_secret,
              balance: userResponse.data.data.balance[0].balance,
              balance_id: userResponse.data.data.balance[0].id,
              companyname,
              role,
              service_rights,
              avatar,
              otp: otp,
            });

            await axios
              .post(`${process.env.REACT_APP_SERVER}/operation/sendotp`, {
                phone: `+26${phone}`,
                message: `OTP : ${otp}`,
              })
              .then((response) => {
                if (response.data.success) {
                  setPerson({
                    id,
                    status,
                    first_name,
                    last_name,
                    email,
                    phone,
                    tfa_secret,
                    balance: userResponse.data.data.balance[0].balance,
                    balance_id: userResponse.data.data.balance[0].id,
                    companyname,
                    role,
                    service_rights,
                    avatar,
                    otp: otp,
                  });
                  localStorage.setItem("role", role);
                }
              });
          })
          .catch((userError) => {
            console.log("Error fetching user data:", userError);
          });

        axios
          .get(`${process.env.REACT_APP_SERVER}/operation/mainbalance`)
          .then((response) => {
            setCgrate({
              balance: response.data.balance,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const accessToken = localStorage.getItem("access_token");
      const expires = localStorage.getItem("expires");

      if (accessToken && expires) {
        const expirationTime = parseInt(expires, 10);
        const currentTime = Date.now();

        if (currentTime > expirationTime) {
          // Token has expired, log the user out
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("expires");
          // You can also redirect the user to the login page or perform other actions
          // depending on your application's requirements.
          console.log("Token expired. User logged out.");
        }
      }
    };

    // Perform any initial user loading or check here (if required)
    checkTokenExpiration();
  }, []);

  return (
    <React.Fragment>
      <userContext.Provider
        value={{
          user,
          person,
          cgrate,
          updateUser,
          setPerson,
          branch,
          setBranch,
          updateCgrate,
        }}
      >
        {children}
      </userContext.Provider>
    </React.Fragment>
  );
};

export default UserContextProvider;
