// VerticalImageSidebar.js
import React from "react";
import sidebarImage from "../../assets/images/sidebar.jpg";
import { Typography } from "antd";

const VerticalImageSidebar = () => {
  const { Title } = Typography;
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        right: 0,
        width: "280px",
        height: "100%",
        background: "#014F8F",
        marginTop: "100px",
      }}
    >
      {/* Your image component goes here */}
      <img
        src={sidebarImage}
        alt="Vertical Image"
        style={{ width: "100%", height: "50%" }}
      />

      <h5
        className="ant-typography font-regular text-muted"
        style={{
          marginLeft: "25px",
          marginRight: "25px",
          color: "white",
          fontStyle: "italic",
          fontSize: "14px",
          whiteSpace: "pre-line",
        }}
      >
        Unit 104 Woodgate House{"\n"}
        Cairo Road, Lusaka, Zambia.{"\n"}
        Mobile: 0974339546{"\n"}
        Whatsapp: 0769900388
      </h5>
    </div>
  );
};

export default VerticalImageSidebar;
