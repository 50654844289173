import { Row, Col, Card, Table, Input, DatePicker, Typography } from "antd";
import axios from "axios";
import React from "react";
import { useState, useEffect, useContext } from "react";
import { userContext } from "../context/UserContext";
import { DownloadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Loader from "../components/loader";
import { CSVLink } from "react-csv";
import moment from "moment";

const Transactions = () => {
  const { Title } = Typography;

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const history = useHistory();
  const { person } = useContext(userContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);

  // const handleReferenceClick = async (reference) => {
  //   try {
  //     // Make a request to your Express backend to get the CSV file
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_SERVER}/${reference}`,
  //       {
  //         responseType: "blob", // Set the responseType to 'blob' to handle binary data
  //       }
  //     );

  //     // Create a Blob from the binary data
  //     const csvBlob = new Blob([response.data], {
  //       type: response.headers["content-type"],
  //     });

  //     // Create a URL for the Blob
  //     const csvFileUrl = URL.createObjectURL(csvBlob);

  //     // Open the CSV file in a new tab or window
  //     window.open(csvFileUrl, "_blank");
  //   } catch (error) {
  //     console.error("Error downloading CSV file:", error.message);
  //   }
  // };

  let url = "";

  if (person.role === "aba355e7-ff5b-44f8-97ec-81e06f7b8a95") {
    url = `https://paysmart.pickmesms.com/items/transactions?fields=*,user_created.companyname&sort=-date_created&filter[user_created][companyname][_eq]=${person.companyname}`;
  } else {
    url = `https://paysmart.pickmesms.com/items/transactions?fields=*,user_created.companyname&sort=-date_created&limit=100`;
  }

  const getItems = async () => {
    try {
      setLoading(true);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
        },
      });
      setLoading(false);

      setItems(response.data.data);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        history.push("/sign-in");
      }
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const formatdate = (inputDate) => {
    const date = new Date(inputDate);

    // Get day, month, and year components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
    const year = date.getFullYear();

    // Get hours, minutes, and seconds components
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Use string formatting to pad with leading zeros if needed
    const formattedDate = `${day.toString().padStart(2, "0")}-${month
      .toString()
      .padStart(2, "0")}-${year}`;

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

    // Combine date and time
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
  };

  const columns = [
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",

      render: (type, record) => (
        <div className="avatar-info">
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* {record.reference.startsWith("uploads/") ? (
              <FileExcelOutlined
                style={{
                  color: "green",
                  fontSize: "40px",
                  marginRight: "20px",
                }}
              />
            ) : (
              <Avatar
                className="shape-avatar"
                shape="square"
                size={40}
                src={providerImages[record.provider]}
              ></Avatar>
            )} */}

            <div>
              <Title level={5}>{type}</Title>
              {/* <Title level={5}>{record.user_created.companyname}</Title> */}
              <h6>{formatdate(record.date_created)}</h6>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      key: "amount",
      render: (amount, record) => (
        <>
          <div className="author-info">
            <h6 level={5}>K{parseFloat(amount).toFixed(2)}</h6>
          </div>
        </>
      ),
    },

    {
      title: "TRANSACTION",
      key: "transtype",
      dataIndex: "transtype",
      render: (transtype, record) => (
        <>
          <div className="author-info">
            <h6 level={5}>{transtype}</h6>
          </div>
        </>
      ),
    },
    {
      title: "RECIPIENT",
      dataIndex: "recipient",
      key: "recipient",
      render: (recipient, record) => (
        <>
          <div className="author-info">
            <h6>{recipient}</h6>
          </div>
        </>
      ),
    },
    {
      title: "NUMBER",
      key: "phone",
      dataIndex: "phone",
      render: (phone, record) => (
        <>
          <div className="author-info">
            <h6 level={4}>{phone}</h6>
          </div>
        </>
      ),
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      render: (status, record) => (
        <>
          <div className="author-info">
            <h6 level={5}>{status}</h6>
          </div>
        </>
      ),
    },
    {
      title: "ACCOUNT",
      key: "account",
      dataIndex: "account",
      render: (account, record) => (
        <>
          <div className="author-info">
            <h6 level={5}>{account}</h6>
          </div>
        </>
      ),
    },

    // {
    //   title: "REF",
    //   key: "reference",
    //   dataIndex: "reference",
    //   render: (reference, record) => (
    //     <>
    //       <div className="author-info">
    //         {reference.startsWith("uploads/") ? (
    //           <Button
    //             type="link"
    //             onClick={() => handleReferenceClick(reference)}
    //             level={5}
    //           >
    //             {reference}
    //           </Button>
    //         ) : (
    //           <h6 level={5}>{reference}</h6>
    //         )}
    //       </div>
    //     </>
    //   ),
    // },
  ];

  const handleDateChange = (dates) => {
    if (dates) {
      const [start, end] = dates;
      console.log(start);
      setStartDate(start);
      setEndDate(end);
      const filteredData = items.filter((item) => {
        const itemDate = moment(item.date_created, "DD-MM-YYYY HH:mm:ss"); // Adjust the format based on your API response
        return itemDate.isSameOrAfter(start) && itemDate.isSameOrBefore(end);
      });

      setFilteredItems(filteredData);
      console.log(filteredData);
    } else {
      // If dates are cleared, reset the filters
      setStartDate(null);
      setEndDate(null);
      setFilteredItems([]);
    }
  };

  return (
    <>
      <div className="tabled">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Transaction History"
                extra={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <DatePicker.RangePicker
                      placeholder={["Start Date", "End Date"]}
                      onChange={handleDateChange}
                    />

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "8px",
                      }}
                    >
                      <CSVLink
                        data={items}
                        filename={"transaction_history.csv"}
                        className="ant-btn"
                      >
                        <DownloadOutlined
                          style={{
                            fontSize: "24px",
                            marginTop: "4px",
                            marginLeft: "4px",
                          }}
                        />
                      </CSVLink>
                    </div>
                  </div>
                }
              >
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={filteredItems.length ? filteredItems : items}
                    className="ant-border-space"
                    pagination={{
                      pageSize: 10,
                    }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default Transactions;
