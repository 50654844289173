import { Row, Col, Card, Typography, Form, Input, Button } from "antd";
import axios from "axios";
import React, { useState, useContext } from "react";
import Loader from "../components/loader";
import { userContext } from "../context/UserContext";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const Zesco = () => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const { setPerson, person, updateCgrate } = useContext(userContext);
  const token = localStorage.getItem("access_token");
  const history = useHistory();

  const onFinish = async (values) => {
    if (parseFloat(values.amount) > parseFloat(person.balance)) {
      toast.error("Insufficient Funds", {
        position: toast.POSITION.TOP_LEFT,
      });
    } else {
      const vphone = values.phone;
      const phone = `+26${vphone}`;

      const postData = {
        provider: "Zesco",
        phone: phone,
        email: values.email,
        amount: values.amount,
        meternumber: values.meternumber,
        vtype: "Token",
        companyname: person.companyname,
        token: token,
        balance_id: person.balance_id,
        balance: person.balance,
      };

      try {
        setLoading(true);
        // Make a POST request using Axios
        await axios
          .post(`${process.env.REACT_APP_SERVER}/operation/purchase`, postData)
          .then(() => {
            form.resetFields();
            setLoading(false);

            updateCgrate();
            refreshBalance();

            Swal.fire({
              title: "ZESCO Units",
              text: `Your Purchase was Successful`,
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                history.push("/dashboard");
              }
            });
          });
      } catch (error) {
        setLoading(false);

        toast.error("An Error Occured", {
          position: toast.POSITION.TOP_LEFT,
        });
        // Handle error cases
      }
    }
  };

  const refreshBalance = () => {
    axios
      .get("https://paysmart.pickmesms.com/users/me?fields=*,balance.*", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then(async (userResponse) => {
        setPerson({
          ...person,
          balance: userResponse.data.data.balance[0].balance,
        });
      })
      .catch((userError) => {
        console.log("Error fetching user data:", userError);
      });
  };

  return (
    <div className="layout-content">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12}>
          <Card>
            <Title level={5}>Buy ZESCO Token</Title>
            <Form name="myForm" onFinish={onFinish} form={form}>
              <Form.Item
                className="username"
                label="Meter Number"
                name="meternumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter meter number",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter meter number",
                    },
                  ]}
                  type="number"
                  placeholder="Meter Number"
                />
              </Form.Item>

              <Form.Item
                className="username"
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter amount",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter amount",
                    },
                  ]}
                  type="number"
                  placeholder="Amount"
                />
              </Form.Item>

              <Form.Item
                className="username"
                label="Phone number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number",
                    },
                  ]}
                  type="number"
                  placeholder="Phone starting with 0"
                />
              </Form.Item>

              <Form.Item
                className="username"
                label="Email Address"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email address",
                  },
                ]}
              >
                <Input
                  rules={[
                    {
                      required: true,
                      message: "Please enter email address",
                    },
                  ]}
                  type="email"
                  placeholder="Valid Email Address"
                />
              </Form.Item>

              <Loader loading={loading} />
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default Zesco;
